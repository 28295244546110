/* General styles for the terms page */
.terms-wrapper {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    line-height: 1.6;
  }
  
  .terms-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading styles */
  h1 {
    font-size: 2rem;
    color: #222;
    margin-bottom: 20px;
  
    padding-bottom: 10px;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #444;
    margin-top: 20px;
  }
  
  /* Paragraph styles */
  p {
    margin: 10px 0;
    font-size: 1rem;
    color: #555;
  }
  
  /* Links */
  a {
    color: #0073e6;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #005bb5;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .terms-content {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    h2 {
      font-size: 1.3rem;
    }
  
    p {
      font-size: 0.95rem;
    }
  }
  