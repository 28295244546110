/* General Navbar Styling */
.navbar {
  background-color: #1b1b32;
  padding: 1rem 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 75px;
}

/* Container for content within the navbar */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo section */
.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-logo {
  text-decoration: none;
  font-size: 1.8rem;
  color: #ffd700;
  font-weight: bold;
  margin-right: 1.5rem;
}

.navbar-logo img {
  height: 50px;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  list-style-type: none;
  margin-left: -27px;
  transition: max-height 0.3s ease; /* For smooth animation */
}

.navbar-link {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  margin-left: 2rem;
  padding: 0.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-link:hover {
  color: #ffd700;
  transform: scale(1.1);
}

/* Hamburger menu styling */
.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger-bar {
  width: 25px;
  height: 3px;
  background-color: #ffd700;
  border-radius: 5px;
}

.navbar-links.active {
  display: block;
  max-height: 200px; /* Set maximum height for dropdown */
}

/* Right-side navbar option */
.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-option {
  background-color: #ffd700;
  color: #1b1b32;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar-option:hover {
  background-color: #ffcc00;
}

/* Mobile responsive design */
@media screen and (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    align-items: center;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    max-height: 0; /* Initially hide the dropdown */
    overflow: hidden; /* Hide content outside the max height */
  }

  /* Navbar links active state on mobile */
  .navbar-links.active {
    display: flex;
    max-height: 200px; /* Allow the links to expand when active */
  }

  .navbar-link {
    margin: 1rem 0;
  }

  /* Show hamburger menu on mobile */
  .hamburger-menu {
    display: flex;
  }

  /* Hide the right-side options on mobile */
  .navbar-right {
    display: none;
  }

  /* When menu is open, show the right-side option */
  .navbar-links.active + .navbar-right {
    display: flex;
    margin-top: 1rem;
  }
}
