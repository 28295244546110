/* General Layout */
.resize-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #1e1e2f, #31314e); /* Gradient background */
  color: #fff;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

/* Resize Card Style */
.resize-card {
  background: #232336; /* Dark background for the card */
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

/* Title and Description */
.resize-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fbc531; /* Yellow color for the title */
  margin-bottom: 1rem;
}

.resize-description {
  font-size: 1rem;
  color: #fbc531; /* Light gray for description */
  margin-bottom: 2rem;
}

/* Upload Section */
.upload-section {
  margin: 1rem 0;
}

#uploadBtn {
  display: none;
}

.upload-button {
  background: #fbc531; /* Yellow background for the upload button */
  color: #1e1e2f;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background: #e1a30f; /* Darker yellow on hover */
}

.icon {
  margin-right: 0.5rem;
}

/* Scale Section */
.scale-section {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.scale-section label {
  display: block;
  font-size: 1rem;
  color: #fbc531;
  margin-bottom: 0.5rem;
}

.scale-section input {
  width: 100%;
  padding: 0.8rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
  color: #333;
}

.scale-section input:focus {
  outline: none;
  border-color: #fbc531; /* Focus color */
}

/* Resize Button */
.resize-button {
  background: #4cd137; /* Green button for resizing */
  color: #1e1e2f;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.resize-button:hover {
  background: #44bd32; /* Darker green on hover */
}

.resize-button:disabled {
  /* background-color: #aaa; */
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .resize-card {
    padding: 1.5rem;
  }

  .resize-title {
    font-size: 1.5rem;
  }

  .resize-description {
    font-size: 0.9rem;
  }
}
