/* PrivacyPolicy.css */

/* General container styling */
.privacy-policy-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  /* Headings */
  .privacy-policy-container h1 {
    text-align: center;
    color: #222;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h2 {
    color: #444;
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  /* Paragraphs */
  .privacy-policy-container p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  /* Strong text emphasis */
  .privacy-policy-container strong {
    color: #0078d7;
  }
  