/* Footer Styles */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Footer {
  background: linear-gradient(135deg, #1a1a2e, #16213e);
  color: white;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Brand Section */
.f-brand {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: white;
}

/* Social Media Icons */
.f-socials {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.social-icon {
  font-size: 1.5rem;
  color: #f0a500;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
  color: #00CAFE;
}

/* Footer Links */
.f-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #f0a500;
}

/* Responsive Design */
@media (max-width: 768px) {
  .f-socials {
    flex-wrap: wrap;
    gap: 10px;
  }

  .f-links {
    flex-wrap: wrap;
    gap: 10px;
  }

  .footer-link {
    font-size: 0.8rem;
  }
}
