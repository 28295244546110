/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background: #f4f4f9;
}

/* Merge Page */
.Merge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #1e1e2f, #31314e);
  color: #fff;
  height: 100vh;
}

/* Compress Page */
.Compress {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #1e1e2f, #31314e);
  color: #fff;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

/* Common Container */
.carde-container {
  background: #232336;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Card Content */
.carde-body {
  padding: 1rem;
}

.carde-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fbc531;
  margin-bottom: 1rem;
}

.carde-text, .compress-description {
  font-size: 1rem;
  color: #dcdde1;
  margin-bottom: 2rem;
}

/* File Upload Section */
.load, .upload-section {
  margin: 1rem 0;
}

#uploadBtn {
  display: none;
}

label {
  background: #fbc531;
  color: #1e1e2f;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.icon-upload {
  margin-right: 0.5rem;
}

/* Merge & Compress Buttons */
button, .download-btn {
  background: #4cd137;
  color: #1e1e2f;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

button:disabled {
  background: #ddd;
  cursor: not-allowed;
}

button:hover:not(:disabled), .download-btn:hover {
  background: #44bd32;
}

/* Loading & Error Messages */
.loading-text, .error-message {
  margin: 1rem 0;
}

.loading-text {
  color: #fbc531;
}

.error-message {
  color: #e84118;
}

/* Responsive Design */
@media (max-width: 768px) {
  .carde-container {
    padding: 1.5rem;
  }

  .carde-title {
    font-size: 1.5rem;
  }

  .carde-text, .compress-description {
    font-size: 0.9rem;
  }

  button {
    width: 100%;
    padding: 1rem;
  }
}
