.Convert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #1e1e2f, #31314e);
  color: #fff;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.convert-container {
  background: #232336;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.convert-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fbc531;
  margin-bottom: 1rem;
}

.convert-description {
  font-size: 1rem;
  color: #dcdde1;
  margin-bottom: 2rem;
}

.button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.upload-section {
  margin: 1rem 0;
}

#uploadBtn {
  display: none;
}

.upload-label {
  background: #fbc531;
  color: #1e1e2f;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.icon-upload {
  margin-right: 0.5rem;
}

.convert-btn {
  background: #4cd137;
  color: #1e1e2f;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.icon-convert {
  margin-right: 0.5rem;
}

.convert-btn:disabled {
  background: #7f8fa6;
  cursor: not-allowed;
}

.convert-btn:hover:not(:disabled) {
  background: #44bd32;
}

.file-list {
  text-align: left;
  margin: 1rem 0;
}

.file-list h3 {
  color: #fbc531;
  margin-bottom: 0.5rem;
}

.file-list ul {
  list-style: none;
  padding: 0;
}

.file-list li {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #dcdde1;
}

.loading-text {
  color: #fbc531;
  margin: 1rem 0;
}

.error-message {
  color: #e84118;
  margin: 1rem 0;
}
