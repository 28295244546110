/* General Home Page Styles */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  background: linear-gradient(135deg, #1a1a2e, #16213e);
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* margin-top: 75px; */
}

.heading{
border-bottom: 2px solid #00CAFE;
}
.container {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Heading Styling */
.text-para h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #f0a500;
  margin-bottom: 20px;
}

.text-para h2 {
  font-size: 1.2rem;
  color: #d1d1d1;
  margin-bottom: 40px;
}

/* Card Container */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

/* Card Styles */
.card {
  background: #232336;
  border: none;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
}

.card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.card-title {
  font-size: 2rem;
  color: #f0a500;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.card-description {
  font-size: 0.9rem;
  color: #f0f0f0;
}

.btn.bg_clr {
  background: #f0a500;
  color: #1a1a2e;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.btn.bg_clr:hover {
  background: #00CAFE;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .text-para h1 {
    font-size: 2rem;
    margin-top: 72px;
}

  .text-para h2 {
    font-size: 1rem;
  }
}
